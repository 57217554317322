
import { defineComponent, ref, watch, onMounted, computed } from 'vue'
import { useDelivery } from '@/composables/useDelivery'
import { useRoute, useRouter } from 'vue-router'
import MCommentPreview from '@/components/MCommentPreview.vue'
import InputAmount from '@/components/InputAmount.vue'
import localforage from 'localforage'
import { ICarWashStockItemToUpdate } from '@/interfaces/ICarWash'
import { IDeliveryProduct } from '@/interfaces/IDelivery'

export default defineComponent({
    components: {
        MCommentPreview,
        InputAmount,
    },
    setup() {
        const {
            isLoading,
            actions,
            fetchDelivery,
            fetchDeliveryStages,
            updateDeliveryStage,
            currentDelivery: delivery,
            getProductQuantity: productQuantity,
            setDeliveryProduct,
        } = useDelivery()
        const route = useRoute()
        const router = useRouter()
        const deliveryId = ref(0)
        const isDeliveryConfirmed = ref(false)
        const isEditActive = ref(false)
        const productsToAdd = ref<ICarWashStockItemToUpdate[]>([])
        const initialDelivery = ref()

        const isWarehouseNote = computed(() => delivery.value?.noteWarehouse)
        const isCourierNote = computed(() => delivery.value?.noteDelivery)
        const isManagerNote = computed(() => delivery.value?.noteCarWash)

        const activeState = ref('delivery-details')

        const deliveryStage = computed(() => {
            return delivery.value?.deliveryStage[0]
        })

        const addProductAmount = (product: IDeliveryProduct, quantity: number) => {
            setDeliveryProduct(product.productId, quantity)
        }

        watch(route, async routeValue => {
            if (routeValue.name === 'DeliveryDetailsManager') {
                deliveryId.value = +route.params.id
                delivery.value = await fetchDelivery(+route.params.id)
            }
        })

        const confirmDelivery = () => {
            isDeliveryConfirmed.value = true
        }

        const activeAction = computed(() => {
            return actions.find(el => el.action === activeState.value)
        })

        const saveCurrentAction = async () => {
            const deliveryActiveAction = {
                ...activeAction.value,
                currentDeliveryId: route.params.id,
            }
            await localforage.setItem('deliveryActiveAction', deliveryActiveAction)
        }

        const resetAmount = () => {
            delivery.value = initialDelivery.value
            productsToAdd.value = []
        }

        const addComment = async () => {
            await saveCurrentAction()
            router.push({
                name: 'DeliveryAddCommentManager',
                params: { id: route.params.id, name: route.name as string },
            })
        }

        const changeDeliveryStatus = async () => {
            await updateDeliveryStage(+route.params.id)
            await localforage.removeItem('tempComment')
            router.push({ name: 'DeliveryListManager' })
        }

        onMounted(async () => {
            await fetchDeliveryStages()
            const storageActiveAction: any = await localforage.getItem('deliveryActiveAction')
            const tempComment = await localforage.getItem('tempComment')
            if (storageActiveAction) {
                if (+storageActiveAction.currentDeliveryId === +route.params.id) {
                    confirmDelivery()
                }
                localforage.removeItem('deliveryActiveAction')
            }
            if (!tempComment || !delivery.value) {
                delivery.value = await fetchDelivery(+route.params.id)
                initialDelivery.value = JSON.parse(JSON.stringify(delivery.value))
            }
        })

        return {
            addProductAmount,
            addComment,
            isEditActive,
            isLoading,
            delivery,
            deliveryStage,
            activeAction,
            isDeliveryConfirmed,
            isWarehouseNote,
            isCourierNote,
            isManagerNote,
            changeDeliveryStatus,
            confirmDelivery,
            productQuantity,
            resetAmount,
        }
    },
})
