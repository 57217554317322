
import { defineComponent, PropType } from 'vue'
export default defineComponent({
    name: 'CommentPreview',
    props: {
        heading: {
            type: String,
            required: true,
        },
        comment: {
            type: [String] as PropType<string | null>,
            required: true,
        },
    },
})
