import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "m-container o-deliveryDetails__comment" }
const _hoisted_2 = { class: "m-container__row" }
const _hoisted_3 = { class: "m-container__column" }
const _hoisted_4 = { class: "a-text a-text--primary a-text--tiny a-text--bold" }
const _hoisted_5 = { class: "m-container__row m-container__row--marginBottom2rem m-container__row--borderBottom" }
const _hoisted_6 = { class: "m-container__column" }
const _hoisted_7 = { class: "a-text a-text--paddingY" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.heading), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.comment), 1)
      ])
    ])
  ]))
}